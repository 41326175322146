@use '../../scss/variables' as *;
@use '@mondough/monzo-ui/src/tokens/globals' as *;

.announcement-dialog {
  max-width: 353px;
  padding: 24px;
  min-height: fit-content;

  & .title {
    font-weight: 800;
    font-size: $font-size-desktop-title;
    max-width: 14ch;
  }

  & .description {
    font-size: $font-size-mobile-marketing-text;
    max-width: 20ch;
  }
}
