@use '../../scss/variables' as *;
@import '~@mondough/monzo-ui/src/tokens/globals';

.wrapper {
  display: flex;
  height: 100%;
  min-height: 60px;
  margin: var(--_margin-top, 12px) var(--_margin-right, 0)
    var(--_margin-bottom, 12px) var(--_margin-left, 0);
  justify-content: space-between;
  background-color: $color-white;
  padding: 12px;
  border-radius: 10px;
  border: 1px solid transparent;

  &:hover {
    background-color: $color-grey4;
  }
  &:active:not(.locked) {
    background-color: $color-grey3;
  }
}

.money-symbol {
  display: none;
}

.money {
  font-weight: 500;
}

.money-minor {
  font-weight: 600;
}

.square-icon {
  background-color: $color-action-blue;
  border-radius: 6px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 24px;
    height: 24px;
  }
}

.left-side-content {
  display: flex;
  gap: $spacing-s;
  align-items: center;
}

.trailing-content {
  margin-left: 8px;
  height: 100%;
  display: flex;
  gap: $spacing-s;
  align-items: center;
  align-self: center;
  justify-content: flex-start;
  text-align: right;
}

.primary-text {
  font-size: 15px;

  @include mq($from: $desktop) {
    font-size: 17px;
  }
}

.secondary-text {
  font-size: 13px;
}

.text-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start;
  align-items: flex-start;
}

.locked:disabled {
  background-color: $color-white;

  .square-icon {
    background-color: $color-grey2;
  }
}

.destructive {
  .square-icon {
    background-color: #ff3b30;
  }
}

.connected-up {
  margin-top: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.connected-down {
  margin-bottom: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: 1px solid $color-grey4;

  &:focus,
  &:active,
  &:active:focus {
    border-bottom: 1px solid $color-grey4 !important;
  }
}

.participants-wrapper {
  display: flex;
  margin-left: 8px;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
}
