@use '../../scss/variables' as *;
@use '@mondough/monzo-ui/src/tokens/globals' as *;

.leave-tab-dialog {
  min-height: unset;

  header {
    text-align: left;
  }
}

.button-group {
  width: 100%;
}
