$font-stack-oldschool-grotesk: 'OldschoolGrotesk', HelveticaNeue, Helvetica Neue,
  Helvetica, Arial, sans-serif;

$font-stack-monzo-text: 'MonzoSansText', HelveticaNeue, Helvetica Neue,
  Helvetica, Arial, sans-serif;

$font-stack-monzo-display: 'MonzoSansDisplay', HelveticaNeue, Helvetica Neue,
  Helvetica, Arial, sans-serif;

/**  Old fonts - being phased out **/

$font-stack-title: Avenir Next, HelveticaNeue, Helvetica Neue, Helvetica, Arial,
  sans-serif;

$font-stack-maison: 'Maison Neue Web', HelveticaNeue, Helvetica Neue, Helvetica,
  Arial, sans-serif;
