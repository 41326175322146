@use '../../scss/variables' as *;
@use '@mondough/monzo-ui/src/tokens/globals' as *;

button.button,
a.button {
  --button-border-radius: 12px;
  border-radius: var(--button-border-radius);
  font-family: MonzoSansText;
  font-weight: 600;
  text-align: center;
  border: none;

  &.primary:disabled,
  &.secondary:disabled,
  &.tertiary:disabled {
    background: $color-grey3;
    color: $color-grey2;
    border: none;

    &:active,
    &:hover,
    &:focus,
    &:focus-visible {
      background: $color-grey3;
    }
  }

  &.primary {
    border-radius: var(--button-border-radius);

    color: white;
    background: $color-action-blue;

    &:focus {
      border: none;
    }

    &:hover,
    &:focus-visible {
      background: darken($color-action-blue, 10%);
    }

    &:active {
      background: $color-sky600;
    }
  }

  &.secondary {
    border: 1px solid darken($color-action-blue, 10%);
    background: #ffffff;
    color: $color-action-blue;

    font-weight: 600;
    line-height: 20px;
    text-align: center;

    &:focus {
      border: 1px solid $color-action-blue;
    }

    &:hover,
    &:focus-visible {
      background: $color-grey4;
    }

    &:active {
      color: $color-white;
      background: darken($color-action-blue, 10%);
      border: 1px solid $color-action-blue;
      &:focus {
        border: 1px solid $color-action-blue;
      }
    }
  }

  &.tertiary {
    background: #c9d0c63d;
    color: $color-action-blue;

    font-weight: 600;
    line-height: 20px;
    text-align: center;

    &:focus {
      border: none;
    }

    &:hover,
    &:focus-visible {
      background: $color-grey4;
      border: none;
    }

    &:active {
      color: $color-white;
      background: darken($color-action-blue, 10%);
      &:focus {
        border: none;
      }
    }
  }

  &.small {
    font-size: 13px;
    padding: 6px 12px;
    @include mq($from: $desktop) {
      font-size: 14px;
      padding: 10px 16px;
    }
  }

  &.regular {
    font-size: 16px;
    min-height: 48px;
    padding: 12px 16px;
  }
}
