@use '../../scss/variables' as *;
@use '@mondough/monzo-ui/src/tokens/globals' as *;

//Mobile-first layout

.tab {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;

  .tab-title {
    font-size: $font-size-mobile-title;
    margin-bottom: $spacing-s;
    margin-left: $spacing-xs;
  }

  .tab-content {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .tab-breadcrumb {
    align-self: flex-start;
    padding: 10px 0 !important;
    margin-top: -2rem;
    font-weight: 400;
  }
}

@include mq($from: $desktop) {
  .tab {
    border-radius: $border-radius-large;

    .tab-title {
      font-size: $font-size-desktop-title;
      font-weight: 800;
    }
  }
}
