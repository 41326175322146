@use '@mondough/monzo-ui/src/tokens/globals' as *;
@use '../../scss/variables' as *;

.dialog {
  place-self: center;
  max-height: 100vh;
  max-width: $max-content-width;
  width: 100vw;

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;

  border-radius: 16px;
  border: none;
  box-shadow: 0px 1px 0px 0px rgba($color-black, 0.2);
  background: var(--background-primary, $color-white);
  padding: 48px;

  &::backdrop {
    background: rgba($color-black, 0.4);
    backdrop-filter: blur(2px);
    transition: all 0.15s ease-out;
  }

  &:focus-within {
    outline: none;
  }

  body:has(&[open]) {
    overflow: hidden;
  }

  .close {
    position: absolute;
    top: 24px;
    right: 24px;
  }

  h2 {
    color: $color-black;
    margin-bottom: 12px;
    text-align: center;

    /* Brand Title */
    font-family: $font-stack-oldschool-grotesk;
    font-size: 34px;
    font-style: normal;
    font-weight: 800;
    line-height: 34px; /* 100% */
  }

  .highlight {
    @extend h2;
    color: $color-hot-coral;
  }

  p {
    color: var(--Content-contentPrimary, $color-navy1);
    text-align: center;

    /* Subheading */
    font-family: $font-stack-monzo-text;
    font-size: var(--Size-Subheading, 15px);
    font-style: normal;
    font-weight: 400;
    line-height: var(--Leading-Subheading, 20px); /* 133.333% */

    & + * {
      margin-top: 32px;
    }
  }
}
