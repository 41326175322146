@use '../../../scss/variables' as *;
@use '@mondough/monzo-ui/src/tokens/globals' as *;

.inner:not(:empty) {
  padding: 24px 32px;
  width: 100%;
  max-width: calc($max-content-width + 32px * 2);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: $spacing-l;
  background-color: $color-white;

  > * {
    color: $color-white;
  }

  .block {
    display: flex;
    flex-direction: column;
    margin-bottom: $spacing-s;
    gap: $spacing-m;
  }

  .title {
    display: flex;
    flex-direction: column;
    gap: $spacing-xs;
    font-size: $font-size-mobile-title;
    width: 100%;
    line-height: 110%;
  }

  .text {
    font-size: $font-size-medium;
    font-weight: 400;
  }

  .icon {
    width: 48px;
    height: 48px;
    background-color: $color-hot-coral;
    padding: 8px;
    border-radius: 12px;
  }

  .button {
    border-color: transparent;
    width: 100%;
  }

  .trust-grid {
    margin: 1rem auto;
    align-items: center;
    justify-items: center;
    gap: $spacing-s;
    img {
      max-height: 100%;
      height: 40px;
      width: auto;
    }
  }

  .download-block {
    background-color: $color-hot-coral;
    border-radius: 37.3px;
    gap: $spacing-s;
    color: $color-white;
    margin-right: -$spacing-l;
    margin-left: -$spacing-l;
    padding: $spacing-l;
    display: flex;
    flex-direction: column;
    align-items: center;

    .app-buttons {
      display: flex;
      gap: $spacing-s;
    }
  }
}

@include mq($from: $desktop) {
  .inner:not(:empty) {
    padding: 32px 64px;
    max-width: 86rem;
    flex-direction: row;
    justify-content: space-around;
    gap: $spacing-xl;

    .title {
      gap: $spacing-s;
      font-size: $font-size-desktop-title;
      flex: 1;
    }

    .text {
      flex: 1;
    }

    .block {
      flex: 1;
      max-width: 465px;
      margin: 0 auto 0 0;
      align-items: flex-start;
      gap: $spacing-m;
    }

    .button {
      width: fit-content;
      margin-top: auto;
    }
  }
}
