@use '../../scss/variables' as *;
@use '@mondough/monzo-ui/src/tokens/globals' as *;

.header {
  width: 100%;
  max-width: 86em;
  margin: 0 auto;
  padding: 0 $spacing-m;
  background: transparent;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $header-height;
  overflow: hidden;

  top: 0;

  .trailing-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    flex-grow: 1;
  }

  @mixin button {
    position: relative;
    font-weight: $font-weight-semi-bold;
  }

  .mobile-button {
    @include button;
    font-size: 13px;
    @include mq($from: $desktop) {
      font-size: 14px;
    }
  }

  .desktop-button {
    @include button;
    @include mq($from: $desktop) {
      color: $color-action-blue;
    }
  }

  .logo,
  .logo > img {
    position: relative;
    cursor: pointer;
    height: 18px;
    max-width: unset;
    @include mq($from: $desktop) {
      height: 20px;
    }
    .white-logo {
      filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%)
        hue-rotate(305deg) brightness(103%) contrast(101%);
    }
  }

  .logo {
    margin: $spacing-xs;
  }
}
